<template>
  <!-- search -->
  <div>
    <div class="col-xl-12 recent-posts-area">
      <h2 class="sidebar-widget-title">Recent Posts</h2>
      <div class="recent-post">
        <div class="recent-post-thumbnail">
          <a href="javascript:void(0)"
            ><img src="@/assets/blog/recent-post-1.png" alt="Habu"
          /></a>
        </div>
        <div class="recent-post-title">
          <a href="javascript:void(0)">
            <h3>Nam ac elit a ante commodo</h3>
          </a>
          <span>by </span><a href="javascript:void(0)">admin</a>
        </div>
      </div>
      <div class="recent-post">
        <div class="recent-post-thumbnail">
          <a href="javascript:void(0)"
            ><img src="@/assets/blog/recent-post-2.png" alt="Habu"
          /></a>
        </div>
        <div class="recent-post-title">
          <a href="javascript:void(0)">
            <h3>Nam ac elit a ante commodo</h3>
          </a>
          <span>by </span><a href="javascript:void(0)">admin</a>
        </div>
      </div>
      <div class="recent-post">
        <div class="recent-post-thumbnail">
          <a href="javascript:void(0)"
            ><img src="@/assets/blog/recent-post-3.png" alt="Habu"
          /></a>
        </div>
        <div class="recent-post-title">
          <a href="javascript:void(0)">
            <h3>Nam ac elit a ante commodo</h3>
          </a>
          <span>by </span><a href="javascript:void(0)">admin</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentPostsSidebar",
};
</script>

<style scoped>
</style>