import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faPhoneAlt,
  faEnvelope,
  faStar,
  faSearch,
  faChevronUp,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faPlayCircle,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faWindowClose,
  faBars,
  faTimes,
  faMugHot,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faSnapchat,
  faBehance,
  faGooglePlusG,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faPhoneAlt,
  faEnvelope,
  faFacebook,
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faSnapchat,
  faStar,
  faSearch,
  faChevronUp,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faPlayCircle,
  faBehance,
  faGooglePlusG,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faWindowClose,
  faBars,
  faTimes,
  faMugHot,
  faAngleRight,
  faWhatsapp
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
