<template>
  <!-- search -->
  <div>
    <div class="col-xl-12 ad-banner">
                            <a href="javascript:void(0)"><img src="@/assets/banner/banner-advert.png" alt="Habu"></a>
                        </div>
  </div>
</template>

<script>
export default {
  name: "AdBannerSidebar",
};
</script>

<style scoped>
</style>