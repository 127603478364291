<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <section class="blog-area">
      <div class="container">
        <div class="section-heading text-center mx-auto">
          <center>
            <h2>404</h2>
            <h2>OPPS! PAGE NOT FOUND</h2>
            <router-link to=/>
            <a
              href="javascript:void(0)"
              class="
                wow
                large-blue-button
                mt-40
                speciality-button
              "
              >Back to home</a
            >
           </router-link>
          </center>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MenuStyle1 from "@/components/layout/MenuStyle1";
import Footer from "@/components/layout/Footer";

export default {
  name: "Home1",
  components: {
    TopHeader,
    MenuStyle1,
    Footer,
  },
};
</script>

<style scoped>
</style>
