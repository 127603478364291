<template>
  <div class="speciality">
    <div class="job-text">
      <div class="sp-svg">
        <svg
          data-name="Why Us"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24"
          height="25"
          viewBox="0 0 24 25"
        >
          <g>
            <image
              width="24"
              height="25"
              xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAADN0lEQVRIiY2WTWhdRRTHf+fNtLGhlFZREaoUbLNwkexiFiliNcTEgJCSlESpECiFunIVsBgSKNqNH+DGjbUQkya6aBVTTcxzYWOC7ap1JVpK6wehKKRQ0JC5PTL3zs279+YmLxfuO2dmzvmf/zn3zMwT+V6p8zQCh4EW4ClgRZQzwH+pm2Qhgi7p2oYAur7YBLyBckrAFtafAJYL9nnwoFt5kDGoOYwBIxmHS8AVlDsCt2LwErBsJqluzYNcdBGoojwfnN9EOR+XJQOUBSgDDST7gUdsJcoxWQTaRFkAjgF/bQGQ1zWXTZ/AtNetidYNPxNoA+ZF6dgmUC2TLLjyeVjrSgIoRwReDbXtKAXYoiSZzAYEJgOJHpRvrXHx0ifB6OiGMhRlWakS2YcyGcY9AjNJiRzPCRxA+UlgsW4pymW/KNMhaLfAN6m/tY6jAeijbQDlZQIy4JmHuZeA2ayND9AZDKsZ1vsFvkD5UuDspuVSBkWZoFaW2aKND9CEsiqa7MwQvUE07ijfsreBCyUZ9KNMSElZgmxAabF2Lba/m0nZy5sC7SgLoSvuCVwuYy5KJzBXQmAYZcxn4B32lBj8CLwsygwwI0oryjWB11DGQyDf0vOlJdT4YMRncBflMYHdKPcLhpcFhlDOifId8IEoo+kmCptysybYG7epXaMaOqGtyCYYfoqyT+A9lNG6zGuyndBFX4syAAyJMr/JRnpfND6iXxdlsA5z/zSjPC7wszw7prsEVlB2CjyK8vcWjkaUaIvdnMq5kGVvxTr+NWsM+49t1pjy0ndWUZpERrF0YVx4w1yXcXRYx7JxXKwEow+t4xfreME6RrIAWSB/MJatmZp+0DhmTGLbG8/5n5Dui6L8hr/NFCv+RqtTCvLHtN+U1XBpvYWy5CcrGSZ/mIhW41i1EW8bR9U6mtfZRYFpVib6QybitIlYMhGNJuJsxfFuup4e1ymTG8AhUaaAI8D10FnTolwF/gQcysMCzwDdKCcEdoQsjwuMpxnHuD2nwmW88SI5gTIs8HTOoagn44+BdwR+z93b/n3lZJjZ/O+Hz8SfN80CT/rvA3Er/yrwA8pXwD/F4PED/A/0pq+mniCmxwAAAABJRU5ErkJggg=="
            ></image>
          </g>
        </svg>
      </div>
      <div class="sp-job-details">
        <p>
          {{title}} <br />
          {{experience}} of Experience. {{location}}
        </p>
      </div>
      <div class="job-apply ml-auto">
        <a href="javascript:void(0)">Apply Now</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobOffer",
  props: ['title', 'experience', 'location'],
};
</script>

<style scoped>
</style>
