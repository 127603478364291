<template>
  <div>
    <div class="container">
      <div class="section-heading section-heading2">
        <h2>Services or Technologies Snapshots</h2>
        <p>Learn more about our creative process!</p>
      </div>
    </div>
    <div class="companies d-flex align-items-center">
      <div class="container">
        <VueSlickCarousel
          v-bind="settings"
          class="row justify-content-between companies-logo"
        >
          <div class="mx-auto text-center">
            <img
              src="@/assets/simple-process/mug-hot-solid.svg"
              class="mx-auto my-auto"
              alt="Plan"
              height="87px"
            />
            <h5 class="mt-2">Discuss</h5>
          </div>
          <div class="mx-auto text-center">
            <img
              src="@/assets/simple-process/plan.svg"
              class="mx-auto my-auto"
              alt="Plan"
              height="87px"
            />
            <h5 class="mt-2">Plan</h5>
          </div>
          <div class="mx-auto text-center">
            <img
              src="@/assets/simple-process/design-dev.svg"
              class="mx-auto my-auto"
              alt="Design & Dev"
              height="87px"
            />
            <h5 class="mt-2">Design & Dev</h5>
          </div>
          <div class="mx-auto text-center">
            <img
              src="@/assets/simple-process/Testing.png"
              class="mx-auto my-auto"
              alt="Testing"
              height="87px"
            />
            <h5 class="mt-2">Testing</h5>
          </div>
          <div class="mx-auto text-center">
            <img
              src="@/assets/simple-process/rocket-solid.svg"
              class="mx-auto my-auto"
              alt="Launch "
              height="87px"
            />
            <h5 class="mt-2">Launch</h5>
          </div>
          <div class="mx-auto text-center">
            <img
              src="@/assets/simple-process/promote.svg"
              class="mx-auto my-auto"
              alt="Promote"
              height="87px"
            />
            <h5 class="mt-2">Promote</h5>
          </div>
        </VueSlickCarousel>
        <!-- <VueSlickCarousel
          v-bind="settings"
          class="row justify-content-between companies-logo"
        >
          <div class="mx-auto text-center">
            <img
              src="@/assets/logo/company-logo/logo-kupa.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-tarabara.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-shiram.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-codex.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-kupa.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-tarabara.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-shiram.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
          <div class="mx-auto">
            <img
              src="@/assets/logo/company-logo/logo-codex.png"
              class="mx-auto my-auto"
              alt="Habu"
            />
          </div>
        </VueSlickCarousel> -->
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "CompaniesSlider",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 376,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.section-heading2 {
  width: 100%;
  text-align: center;
  margin: 40px 0;
}
</style>







