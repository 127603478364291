<template>
  <div>
    <div class="custom-projects">
        <div class="container d-lg-flex align-items-center">
            <div class="section-heading">
                <h1>Custom Projects</h1>
                <p>We have people of multiple kind in the house. Together we can provide high quality work to satisfy
                    you. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div class="custom-project-button ml-auto">
                <button>Get A Quote</button>
            </div>
        </div>
        <div class="custom-projects-shape-2">
            <img src="@/assets/shapes/shape-02.png" alt="Habu">
        </div>
        <div class="custom-projects-circle-1">
            <img src="@/assets/shapes/shape-circle.png" class="wow animate__animated animate__pulse animate__infinite" alt="Habu">
        </div>
        <div class="custom-projects-circle-2">
            <img src="@/assets/shapes/shape-circle.png" alt="Habu">
        </div>
        <div class="custom-projects-circle-3">
            <img src="@/assets/shapes/shape-circle.png" class="wow animate__animated animate__pulse animate__infinite" alt="Habu">
        </div>
        <div class="custom-projects-circle-4"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomProjects"
};

</script>

<style scoped>
</style>
