<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div class="row justify-content-between">
          <div
            class="col-xl-5 col-lg-6 about-area-image wow animate__animated animate__fadeInLeft animate__delay-1s"
          >
            <img src="@/assets/logo/company-logo/about-uslogo.png" alt="Habu" />
          </div>
          <div
            class="col-lg-6 speciality-text wow animate__animated animate__fadeInRight animate__delay-1s"
          >
            <div class="section-heading w-100 mb-28 mt-0">
              <h2>Who we are?</h2>
              <p>
                GlideTech Solutions is your one stop solution for your all your
                tech needs. We aim to techspire lives and have been effectively
                working on projects from all over the world. Some of our notable
                clients are from the United States, Australia and Canada. We
                have experienced developers ranging from two to six years in
                various technologies. We have had a successful track record in
                efficient and timely delivery of our clients’ needs.
              </p>
            </div>
            <!-- <div class="speciality">
              <div class="sp-text">
                <svg
                  data-name="Why Us"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                >
                  <g>
                    <image
                      width="24"
                      height="25"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAADN0lEQVRIiY2WTWhdRRTHf+fNtLGhlFZREaoUbLNwkexiFiliNcTEgJCSlESpECiFunIVsBgSKNqNH+DGjbUQkya6aBVTTcxzYWOC7ap1JVpK6wehKKRQ0JC5PTL3zs279+YmLxfuO2dmzvmf/zn3zMwT+V6p8zQCh4EW4ClgRZQzwH+pm2Qhgi7p2oYAur7YBLyBckrAFtafAJYL9nnwoFt5kDGoOYwBIxmHS8AVlDsCt2LwErBsJqluzYNcdBGoojwfnN9EOR+XJQOUBSgDDST7gUdsJcoxWQTaRFkAjgF/bQGQ1zWXTZ/AtNetidYNPxNoA+ZF6dgmUC2TLLjyeVjrSgIoRwReDbXtKAXYoiSZzAYEJgOJHpRvrXHx0ifB6OiGMhRlWakS2YcyGcY9AjNJiRzPCRxA+UlgsW4pymW/KNMhaLfAN6m/tY6jAeijbQDlZQIy4JmHuZeA2ayND9AZDKsZ1vsFvkD5UuDspuVSBkWZoFaW2aKND9CEsiqa7MwQvUE07ijfsreBCyUZ9KNMSElZgmxAabF2Lba/m0nZy5sC7SgLoSvuCVwuYy5KJzBXQmAYZcxn4B32lBj8CLwsygwwI0oryjWB11DGQyDf0vOlJdT4YMRncBflMYHdKPcLhpcFhlDOifId8IEoo+kmCptysybYG7epXaMaOqGtyCYYfoqyT+A9lNG6zGuyndBFX4syAAyJMr/JRnpfND6iXxdlsA5z/zSjPC7wszw7prsEVlB2CjyK8vcWjkaUaIvdnMq5kGVvxTr+NWsM+49t1pjy0ndWUZpERrF0YVx4w1yXcXRYx7JxXKwEow+t4xfreME6RrIAWSB/MJatmZp+0DhmTGLbG8/5n5Dui6L8hr/NFCv+RqtTCvLHtN+U1XBpvYWy5CcrGSZ/mIhW41i1EW8bR9U6mtfZRYFpVib6QybitIlYMhGNJuJsxfFuup4e1ymTG8AhUaaAI8D10FnTolwF/gQcysMCzwDdKCcEdoQsjwuMpxnHuD2nwmW88SI5gTIs8HTOoagn44+BdwR+z93b/n3lZJjZ/O+Hz8SfN80CT/rvA3Er/yrwA8pXwD/F4PED/A/0pq+mniCmxwAAAABJRU5ErkJggg=="
                    />
                  </g>
                </svg>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing
                  adipisicing elit.
                </p>
              </div>
            </div> -->
            <!-- <div class="speciality">
              <div class="sp-text">
                <svg
                  data-name="Why Us"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                >
                  <g>
                    <image
                      width="24"
                      height="25"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAADN0lEQVRIiY2WTWhdRRTHf+fNtLGhlFZREaoUbLNwkexiFiliNcTEgJCSlESpECiFunIVsBgSKNqNH+DGjbUQkya6aBVTTcxzYWOC7ap1JVpK6wehKKRQ0JC5PTL3zs279+YmLxfuO2dmzvmf/zn3zMwT+V6p8zQCh4EW4ClgRZQzwH+pm2Qhgi7p2oYAur7YBLyBckrAFtafAJYL9nnwoFt5kDGoOYwBIxmHS8AVlDsCt2LwErBsJqluzYNcdBGoojwfnN9EOR+XJQOUBSgDDST7gUdsJcoxWQTaRFkAjgF/bQGQ1zWXTZ/AtNetidYNPxNoA+ZF6dgmUC2TLLjyeVjrSgIoRwReDbXtKAXYoiSZzAYEJgOJHpRvrXHx0ifB6OiGMhRlWakS2YcyGcY9AjNJiRzPCRxA+UlgsW4pymW/KNMhaLfAN6m/tY6jAeijbQDlZQIy4JmHuZeA2ayND9AZDKsZ1vsFvkD5UuDspuVSBkWZoFaW2aKND9CEsiqa7MwQvUE07ijfsreBCyUZ9KNMSElZgmxAabF2Lba/m0nZy5sC7SgLoSvuCVwuYy5KJzBXQmAYZcxn4B32lBj8CLwsygwwI0oryjWB11DGQyDf0vOlJdT4YMRncBflMYHdKPcLhpcFhlDOifId8IEoo+kmCptysybYG7epXaMaOqGtyCYYfoqyT+A9lNG6zGuyndBFX4syAAyJMr/JRnpfND6iXxdlsA5z/zSjPC7wszw7prsEVlB2CjyK8vcWjkaUaIvdnMq5kGVvxTr+NWsM+49t1pjy0ndWUZpERrF0YVx4w1yXcXRYx7JxXKwEow+t4xfreME6RrIAWSB/MJatmZp+0DhmTGLbG8/5n5Dui6L8hr/NFCv+RqtTCvLHtN+U1XBpvYWy5CcrGSZ/mIhW41i1EW8bR9U6mtfZRYFpVib6QybitIlYMhGNJuJsxfFuup4e1ymTG8AhUaaAI8D10FnTolwF/gQcysMCzwDdKCcEdoQsjwuMpxnHuD2nwmW88SI5gTIs8HTOoagn44+BdwR+z93b/n3lZJjZ/O+Hz8SfN80CT/rvA3Er/yrwA8pXwD/F4PED/A/0pq+mniCmxwAAAABJRU5ErkJggg=="
                    />
                  </g>
                </svg>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing.</p>
              </div>
            </div> -->
            <!-- <div class="speciality">
              <div class="sp-text">
                <svg
                  data-name="Why Us"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                >
                  <g>
                    <image
                      width="24"
                      height="25"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAADN0lEQVRIiY2WTWhdRRTHf+fNtLGhlFZREaoUbLNwkexiFiliNcTEgJCSlESpECiFunIVsBgSKNqNH+DGjbUQkya6aBVTTcxzYWOC7ap1JVpK6wehKKRQ0JC5PTL3zs279+YmLxfuO2dmzvmf/zn3zMwT+V6p8zQCh4EW4ClgRZQzwH+pm2Qhgi7p2oYAur7YBLyBckrAFtafAJYL9nnwoFt5kDGoOYwBIxmHS8AVlDsCt2LwErBsJqluzYNcdBGoojwfnN9EOR+XJQOUBSgDDST7gUdsJcoxWQTaRFkAjgF/bQGQ1zWXTZ/AtNetidYNPxNoA+ZF6dgmUC2TLLjyeVjrSgIoRwReDbXtKAXYoiSZzAYEJgOJHpRvrXHx0ifB6OiGMhRlWakS2YcyGcY9AjNJiRzPCRxA+UlgsW4pymW/KNMhaLfAN6m/tY6jAeijbQDlZQIy4JmHuZeA2ayND9AZDKsZ1vsFvkD5UuDspuVSBkWZoFaW2aKND9CEsiqa7MwQvUE07ijfsreBCyUZ9KNMSElZgmxAabF2Lba/m0nZy5sC7SgLoSvuCVwuYy5KJzBXQmAYZcxn4B32lBj8CLwsygwwI0oryjWB11DGQyDf0vOlJdT4YMRncBflMYHdKPcLhpcFhlDOifId8IEoo+kmCptysybYG7epXaMaOqGtyCYYfoqyT+A9lNG6zGuyndBFX4syAAyJMr/JRnpfND6iXxdlsA5z/zSjPC7wszw7prsEVlB2CjyK8vcWjkaUaIvdnMq5kGVvxTr+NWsM+49t1pjy0ndWUZpERrF0YVx4w1yXcXRYx7JxXKwEow+t4xfreME6RrIAWSB/MJatmZp+0DhmTGLbG8/5n5Dui6L8hr/NFCv+RqtTCvLHtN+U1XBpvYWy5CcrGSZ/mIhW41i1EW8bR9U6mtfZRYFpVib6QybitIlYMhGNJuJsxfFuup4e1ymTG8AhUaaAI8D10FnTolwF/gQcysMCzwDdKCcEdoQsjwuMpxnHuD2nwmW88SI5gTIs8HTOoagn44+BdwR+z93b/n3lZJjZ/O+Hz8SfN80CT/rvA3Er/yrwA8pXwD/F4PED/A/0pq+mniCmxwAAAABJRU5ErkJggg=="
                    />
                  </g>
                </svg>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing
                  consectetur.
                </p>
              </div>
            </div> -->
            <!-- <div class="speciality">
              <div class="sp-text">
                <svg
                  data-name="Why Us"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                >
                  <g>
                    <image
                      width="24"
                      height="25"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAADN0lEQVRIiY2WTWhdRRTHf+fNtLGhlFZREaoUbLNwkexiFiliNcTEgJCSlESpECiFunIVsBgSKNqNH+DGjbUQkya6aBVTTcxzYWOC7ap1JVpK6wehKKRQ0JC5PTL3zs279+YmLxfuO2dmzvmf/zn3zMwT+V6p8zQCh4EW4ClgRZQzwH+pm2Qhgi7p2oYAur7YBLyBckrAFtafAJYL9nnwoFt5kDGoOYwBIxmHS8AVlDsCt2LwErBsJqluzYNcdBGoojwfnN9EOR+XJQOUBSgDDST7gUdsJcoxWQTaRFkAjgF/bQGQ1zWXTZ/AtNetidYNPxNoA+ZF6dgmUC2TLLjyeVjrSgIoRwReDbXtKAXYoiSZzAYEJgOJHpRvrXHx0ifB6OiGMhRlWakS2YcyGcY9AjNJiRzPCRxA+UlgsW4pymW/KNMhaLfAN6m/tY6jAeijbQDlZQIy4JmHuZeA2ayND9AZDKsZ1vsFvkD5UuDspuVSBkWZoFaW2aKND9CEsiqa7MwQvUE07ijfsreBCyUZ9KNMSElZgmxAabF2Lba/m0nZy5sC7SgLoSvuCVwuYy5KJzBXQmAYZcxn4B32lBj8CLwsygwwI0oryjWB11DGQyDf0vOlJdT4YMRncBflMYHdKPcLhpcFhlDOifId8IEoo+kmCptysybYG7epXaMaOqGtyCYYfoqyT+A9lNG6zGuyndBFX4syAAyJMr/JRnpfND6iXxdlsA5z/zSjPC7wszw7prsEVlB2CjyK8vcWjkaUaIvdnMq5kGVvxTr+NWsM+49t1pjy0ndWUZpERrF0YVx4w1yXcXRYx7JxXKwEow+t4xfreME6RrIAWSB/MJatmZp+0DhmTGLbG8/5n5Dui6L8hr/NFCv+RqtTCvLHtN+U1XBpvYWy5CcrGSZ/mIhW41i1EW8bR9U6mtfZRYFpVib6QybitIlYMhGNJuJsxfFuup4e1ymTG8AhUaaAI8D10FnTolwF/gQcysMCzwDdKCcEdoQsjwuMpxnHuD2nwmW88SI5gTIs8HTOoagn44+BdwR+z93b/n3lZJjZ/O+Hz8SfN80CT/rvA3Er/yrwA8pXwD/F4PED/A/0pq+mniCmxwAAAABJRU5ErkJggg=="
                    />
                  </g>
                </svg>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing.</p>
              </div>
            </div> -->
            <!-- <div class="speciality">
              <div class="sp-text">
                <svg
                  data-name="Why Us"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                >
                  <g>
                    <image
                      width="24"
                      height="25"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAADN0lEQVRIiY2WTWhdRRTHf+fNtLGhlFZREaoUbLNwkexiFiliNcTEgJCSlESpECiFunIVsBgSKNqNH+DGjbUQkya6aBVTTcxzYWOC7ap1JVpK6wehKKRQ0JC5PTL3zs279+YmLxfuO2dmzvmf/zn3zMwT+V6p8zQCh4EW4ClgRZQzwH+pm2Qhgi7p2oYAur7YBLyBckrAFtafAJYL9nnwoFt5kDGoOYwBIxmHS8AVlDsCt2LwErBsJqluzYNcdBGoojwfnN9EOR+XJQOUBSgDDST7gUdsJcoxWQTaRFkAjgF/bQGQ1zWXTZ/AtNetidYNPxNoA+ZF6dgmUC2TLLjyeVjrSgIoRwReDbXtKAXYoiSZzAYEJgOJHpRvrXHx0ifB6OiGMhRlWakS2YcyGcY9AjNJiRzPCRxA+UlgsW4pymW/KNMhaLfAN6m/tY6jAeijbQDlZQIy4JmHuZeA2ayND9AZDKsZ1vsFvkD5UuDspuVSBkWZoFaW2aKND9CEsiqa7MwQvUE07ijfsreBCyUZ9KNMSElZgmxAabF2Lba/m0nZy5sC7SgLoSvuCVwuYy5KJzBXQmAYZcxn4B32lBj8CLwsygwwI0oryjWB11DGQyDf0vOlJdT4YMRncBflMYHdKPcLhpcFhlDOifId8IEoo+kmCptysybYG7epXaMaOqGtyCYYfoqyT+A9lNG6zGuyndBFX4syAAyJMr/JRnpfND6iXxdlsA5z/zSjPC7wszw7prsEVlB2CjyK8vcWjkaUaIvdnMq5kGVvxTr+NWsM+49t1pjy0ndWUZpERrF0YVx4w1yXcXRYx7JxXKwEow+t4xfreME6RrIAWSB/MJatmZp+0DhmTGLbG8/5n5Dui6L8hr/NFCv+RqtTCvLHtN+U1XBpvYWy5CcrGSZ/mIhW41i1EW8bR9U6mtfZRYFpVib6QybitIlYMhGNJuJsxfFuup4e1ymTG8AhUaaAI8D10FnTolwF/gQcysMCzwDdKCcEdoQsjwuMpxnHuD2nwmW88SI5gTIs8HTOoagn44+BdwR+z93b/n3lZJjZ/O+Hz8SfN80CT/rvA3Er/yrwA8pXwD/F4PED/A/0pq+mniCmxwAAAABJRU5ErkJggg=="
                    />
                  </g>
                </svg>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing dolor sit
                  amet.
                </p>
              </div>
            </div> -->
            <router-link to="/contact-us/#feel-free-block">
              <a
                href="javascript:void(0)"
                class="wow animate__animated animate__flipInX large-blue-button mt-40 speciality-button"
                >Get a Free consultation</a
              >
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CompanyDescription",
};
</script>

<style scoped>
.about-area-image img {
  height: auto;
}
</style>
