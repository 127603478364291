<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="About Us" parentPage="Home" />
    <CompanyDescription />
    <div class="companiesSlider"><CompaniesSlider /></div>

    <!-- <OurTeam /> -->
    <!-- <Experience />
    <Reviews /> -->
    <Footer />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import CompanyDescription from "@/components/page_components/about_components/CompanyDescription";
import CompaniesSlider from "@/components/common/companiesSlider";
// import Reviews from "@/components/common/Reviews";
import Footer from "@/components/layout/Footer";
// import OurTeam from "@/components/page_components/about_components/OurTeam";
// import Experience from "@/components/page_components/about_components/Experience";

export default {
  name: "About",
  components: {
    TopHeader,
    MenuStyle1,
    PageHeader,
    CompanyDescription,
    CompaniesSlider,
    // OurTeam,
    // Experience,
    // Reviews,
    Footer,
  },
  mounted() {
    window.scroll(0, 0);
  },
};
</script>

<style scoped>
.companiesSlider {
  margin-bottom: 100px;
}
</style>
